import React, { useEffect, useState } from 'react'

import { endpoints } from '../endpoints'

export default function Recipes ({ classnames, styles })
{
    const [ recipes  , setRecipes   ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        fetch(endpoints.recipes)
            .then((response) => response.json())
            .then((data) => {
                setRecipes(data.body)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }, []);

    if ( isLoading )
        return (
            <>
                <div className={`${classnames} overflow-y-scroll no-scrollbar flex justify-center items-center text-center`} style={styles}>
                    <h2 className="font-bold">Loading...</h2>
                </div>
            </>
        );

    return (
        <div className={`${classnames}`} style={styles}>
            <h2 className="text-2xl text-center font-bold">Recipes</h2>
            <br />
            {
                recipes.map((recipe) => (
                    <div key={recipe.name}>
                        <span className='text-xl text-black font-bold'>
                            {recipe.name}
                        </span>
                        <p className="text-base">
                            {
                                recipe.ingredients.map(
                                    (ingredient) => ingredient.name
                                )
                                    .join(", ")
                                    .replace(
                                        /(^.)/,
                                        (match) => match.toUpperCase()
                                    )
                            }
                        </p>
                        <br />
                    </div>
                ))
            }
        </div>
    )
};