import React, { useEffect, useState } from 'react'

import { endpoints } from '../endpoints'

export default function OrderStatus ({ classnames, styles, flag })
{
    const [kitchenOrdersAndStatus, setKitchenOrdersAndStatus] = useState([]);

    useEffect(() => {
        fetch(endpoints.orderStatus)
            .then((response) => response.json())
            .then((data) => setKitchenOrdersAndStatus(data.body))
            .catch(error => console.log(error));

        const interval = setInterval(() => {
            fetch(endpoints.orderStatus)
                .then((response) => response.json())
                .then((data) => setKitchenOrdersAndStatus(data.body))
                .catch(error => console.log(error));
        }, 10000);

        return (() => {
            clearInterval(interval);
        });
    }, [flag]);

    return (
        <>
            <div className={`${classnames} overflow-y-scroll no-scrollbar`} style={styles}>
                <h2 className="text-2xl text-center font-bold">On Going Orders</h2>
                <br />
                {
                    kitchenOrdersAndStatus.map((order) => (
                        <div className="w-[100%] flex flex-row items-center" key={(new Date()).toString() + Math.random()}>
                            <span className="w-[10%] text-black font-bold">
                                {
                                    order[0]
                                }
                            </span>
                            <span className='w-[70%] text-lg text-black font-bold'>
                                {
                                    order[1]
                                }
                            </span>
                            <span className="w-[20%] text-base text-right">
                                {
                                    <div className="flex flex-row items-center justify-center">
                                        <div className="w-[10px] h-[10px] rounded-full" style={{
                                            backgroundColor: order[2] === "ready" ? "green" : "red"}}
                                        />
                                        <span className="pl-2">{order[2]}</span>
                                    </div>
                                }
                            </span>
                        </div>
                    ))
                }
            </div>
        </>
    )
};