import React, { useState } from 'react'
import { endpoints } from '../endpoints'

export default function RequestOrder ({flag, setFlag})
{
    const [ isLoading, setIsLoading ] = useState(false);

    function requestOrderAndUpdate ()
    {
        setIsLoading(true);

        fetch(endpoints.requestOrder, {
            method: "POST"
        })
            .then(() => {
                setIsLoading(false)
                setFlag(!flag);
            })
            .catch((error) => console.error(error));

    }

    if ( isLoading )
        return (
            <button className="bg-gray-400 w-[300px] py-[20px] px-[40px] rounded-full border-[3px] border-black hover:scale-110 transition-all">
                Submitting order
            </button>
        )

    return (
        <button className="bg-orange-400 w-[300px] py-[20px] px-[40px] rounded-full border-[3px] border-black hover:scale-110 transition-all" onClick={requestOrderAndUpdate}>
            Request new order
        </button>
    )
};