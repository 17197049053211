import React, { useRef } from 'react'

const Pagination = ({ currentPage, itemsPerPage, totalItems, paginate }) => {
    const pageNumbers = [];

    // Calculate the number of pages
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++)
        pageNumbers.push(i);

    const scrollContainer = useRef(null);

    const handleWheelScroll = (event) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += event.deltaY;
        }
    };

    return (
        <ul
            ref={ scrollContainer }
            onWheel={ handleWheelScroll }
            className="absolute w-[90%] h-[40px] bottom-7 flex flex-row gap-1 overflow-x-auto no-scrollbar">
            {
                pageNumbers.map(number => (
                    <li key={number} className={`border-black min-w-[40px] min-h-[40px] border-[2px] flex justify-center items-center ${number === currentPage ? "bg-gray-400" : ""} hover:bg-gray-400 rounded-lg`}>
                        <span onClick={() => paginate(number)} className="text-black w-full h-full text-center leading-[35px]">{ number }</span>
                    </li>
                ))
            }
        </ul>
    )
}

export default Pagination