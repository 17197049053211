import { useState } from 'react';

import './App.css';

import KitchenOrders           from './components/KitchenOrders';
import OrderStatus             from './components/OrderStatus';
import Recipes                 from './components/Recipes';
import RequestOrder            from './components/RequestOrder';
import WarehouseShoppingRecord from './components/WarehouseShoppingRecord';
import WarehouseStock          from './components/WarehouseStock';

function App ()
{
    const [flag, setFlag] = useState(true);

    return (
        <div className="flex flex-col justify-center items-center">
            <div className="app-grid grid px-[20px] py-[20px]">
                <Recipes                 classnames="relative bg-blue-300   w-full h-full p-[20px] rounded-[40px] border-[3px] border-black" styles={{gridArea: "recipes"}}/>
                <OrderStatus             flag={flag} classnames="relative bg-green-300  w-full h-full p-[20px] rounded-[40px] border-[3px] border-black" styles={{gridArea: "order-status"}}/>
                <KitchenOrders           classnames="relative bg-yellow-300 w-full h-full p-[20px] rounded-[40px] border-[3px] border-black" styles={{gridArea: "kitchen-orders"}}/>
                <WarehouseStock          classnames="relative bg-purple-300 w-full h-full p-[20px] rounded-[40px] border-[3px] border-black" styles={{gridArea: "warehouse-stock"}}/>
                <WarehouseShoppingRecord classnames="relative bg-red-300    w-full h-full p-[20px] rounded-[40px] border-[3px] border-black" styles={{gridArea: "warehouse-shopping-record"}}/>
            </div>
            <RequestOrder flag={flag} setFlag={setFlag}/>
        </div>
    );
}

export default App;
