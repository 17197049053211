import React, { useEffect, useState } from 'react'

import Pagination from './Pagination';

import { endpoints } from '../endpoints'

export default function KitchenOrders ({ classnames, styles })
{
    const [ kitchenOrders, setKitchenOrders ] = useState([]);
    const [ isLoading    , setIsLoading     ] = useState(false);
    const [ currentPage  , setCurrentPage   ] = useState(1);
    const [ ordersPerPage                   ] = useState(8);
    const [ refreshFlag  , setRefreshFlag   ] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        fetch(endpoints.kitchenOrders)
            .then((response) => response.json())
            .then((data) => {
                setKitchenOrders(data.body.sort((a, b) => {
                    if (a.id > b.id)
                        return -1

                    else if (a.id < b.id)
                        return 1

                    return 0;
                }));
                setIsLoading(false);
            })
            .catch(error => console.log(error));
    }, [refreshFlag]);

    const indexOfLastOrderInPage  = currentPage * ordersPerPage;
    const indexOfFirstOrderInPage = indexOfLastOrderInPage - ordersPerPage;

    const currentOrders = kitchenOrders.slice(indexOfFirstOrderInPage, indexOfLastOrderInPage);

    function paginate (number)
    {
        setCurrentPage(number);
    }

    if ( isLoading )
        return (
            <>
                <div className={`${classnames} overflow-y-scroll no-scrollbar flex justify-center items-center text-center`} style={styles}>
                    <h2 className="font-bold">Loading...</h2>
                </div>
            </>
        );

    return (
        <>
            <div className={`${classnames} overflow-y-scroll no-scrollbar`} style={styles}>
                <div className="relative flex justify-center items-center">
                    <h2 className="text-2xl text-center font-bold">Kitchen Orders Record</h2>
                    <svg onClick={() => setRefreshFlag(!refreshFlag)} fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="size-6 absolute right-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
                <br />
                {
                    currentOrders.map((order) => (
                        <div className="w-[100%] flex flex-row items-center" key={order["created_at"]}>
                            <span className="w-[10%] text-black font-bold">
                                {
                                    order.id
                                }
                            </span>
                            <span className='w-[70%] text-lg text-black font-bold'>
                                {
                                    order.recipe.replace(
                                        /(^.)/,
                                        (match) => match.toUpperCase()
                                    )
                                }
                            </span>
                            <span className="w-[20%] text-base text-right">
                                {
                                    new Date(order["created_at"]).toLocaleDateString()
                                }
                            </span>
                        </div>
                    ))
                }
                <br />
                <Pagination itemsPerPage={ordersPerPage} totalItems={kitchenOrders.length} currentPage={currentPage} paginate={paginate}/>
            </div>
        </>
    )
};