import React, { useEffect, useState } from 'react'

import Pagination from './Pagination';

import { endpoints } from '../endpoints'

export default function WarehouseShoppingRecord ({ classnames, styles })
{
    const [ purchases  , setPurchases   ] = useState([]);
    const [ isLoading  , setIsLoading   ] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ purchasesPerPage            ] = useState(8);
    const [ refreshFlag, setRefreshFlag ] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        fetch(endpoints.warehouseShoppingRecord)
            .then((response) => response.json())
            .then((data) => {
                setPurchases(data.body.sort((a, b) => {
                    if (a.id > b.id)
                        return -1

                    else if (a.id < b.id)
                        return 1

                    return 0;
                }));
                setIsLoading(false);
            })
            .catch(error => console.log(error));
    }, [refreshFlag]);

    const indexOfLastPurchaseInPage  = currentPage * purchasesPerPage;
    const indexOfFirstPurchaseInPage = indexOfLastPurchaseInPage - purchasesPerPage;

    const currentPurchases = purchases.slice(indexOfFirstPurchaseInPage, indexOfLastPurchaseInPage);

    function paginate (number)
    {
        setCurrentPage(number);
    }

    if ( isLoading )
        return (
            <>
                <div className={`${classnames} overflow-y-scroll no-scrollbar flex justify-center items-center text-center`} style={styles}>
                    <h2 className="font-bold">Loading...</h2>
                </div>
            </>
        );

    return (
        <>
            <div className={`${classnames} overflow-y-scroll no-scrollbar`} style={styles}>
                <div className="relative flex justify-center items-center">
                    <h2 className="text-2xl text-center font-bold">Warehouse Shopping Record</h2>
                    <svg onClick={() => setRefreshFlag(!refreshFlag)} fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="size-6 absolute right-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
                <br />
                {
                    currentPurchases.map((purchase) => (
                        <div className="w-[100%] flex flex-row" key={purchase.date}>
                            <span className="w-[10%] text-black font-bold">
                                {
                                    purchase.id
                                }
                            </span>
                            <span className='w-[40%] text-xl text-black font-bold'>
                                {
                                    purchase.ingredient.replace(
                                        /(^.)/,
                                        (match) => match.toUpperCase()
                                    )
                                }
                            </span>
                            <span className="w-[10%] text-center">
                                {
                                    purchase.count
                                }
                            </span>
                            <span className="w-[40%] text-right">
                                {
                                    new Date(purchase.date).toLocaleDateString()
                                }
                            </span>
                        </div>
                    ))
                }
                <Pagination itemsPerPage={purchasesPerPage} totalItems={purchases.length} paginate={paginate} currentPage={currentPage}/>
            </div>
        </>
    )
};